import { getDiscountDate } from 'theme/helpers';
import { getFormatTimer } from 'theme/helpers/timer';
import { declension } from 'theme/helpers/text';
import isNil from 'lodash/isNil'
import { getThumbnailPath } from '@vue-storefront/core/helpers';
import { checkIfDateExpired } from 'theme/helpers/date';

export default {
  name: 'ProductLabels',
  computed: {
    showDiscount () {
      if (!this.product || !this.showDiscountDate) return false
      const discount = this.product.special_price_discount
      return !isNil(discount) && Number(discount) !== 0
    },
    showDiscountDate () {
      const dateTo = this.product.special_price_to_date;
      if (!dateTo) return false;
      return !checkIfDateExpired(dateTo);
    },
    showLabelPromotion () {
      return this.promotionLabels.length
    },
    showLabelNovelties () {
      if (!this.product.is_new || !this.product.news_from_date || !this.product.news_to_date) return false

      const date_from = new Date(this.product.news_from_date.replace(' ', 'T'))
      const date_to = new Date(this.product.news_to_date.replace(' ', 'T'))

      if (isNaN(date_from.valueOf()) || isNaN(date_to.valueOf())) return false

      return date_from < new Date() < date_to
    },
    showMarkdownTag () {
      return this.product && this.product['markdown_discount']
    },
    tagLabels () {
      const labels = []

      if (this.showDiscount) {
        labels.push({
          type: 'discount',
          color: '#BFEAB6',
          text: '',
          position: labels.length + 1
        })
      }

      if (this.showLabelNovelties) {
        labels.push({
          type: 'novelties',
          color: '#E1F67A',
          text: this.$t('Novelty'),
          position: labels.length + 1
        })
      }

      if (this.showMarkdownTag) {
        labels.push({
          type: 'markdown',
          color: '#FC9F9F',
          text: `${this.$t('Markdown')} -${this.product.markdown_discount}%`,
          position: labels.length + 1
        })
      }

      if (this.showLabelPromotion) {
        this.promotionLabels.forEach(e => {
          if (e.text) {
            labels.push({
              type: 'promotion',
              class: 'badge-promotion',
              color: e.color || '#61ABB6',
              text: e.text.slice(0, 22),
              date_from: e.date_from,
              date_to: e.date_to,
              add_date: e.add_date,
              add_countdown: e.add_countdown,
              position: labels.length + 1
            })
          }
        })
      }

      return labels.slice(0, 3)
    },
    svgLabels () {
      return (this.product.promotion_label || [])
        .filter(e => e && e.icon && this.isDateInRange(e))
        .slice(0, 2)
        .map(e => getThumbnailPath(e.icon, 50, 50))
    },
    promotionLabels () {
      return (this.product.promotion_label || [])
        .filter(e => e.text && this.isDateInRange(e))
    }
  },
  methods: {
    getDateFromString (dateString) {
      const date = new Date(dateString);
      if (isNaN(Date.parse(date))) {
        return null;
      }
      return date;
    },
    isDateInRange (label = {}) {
      const { date_from, date_to } = label;
      if (!date_from || !date_to) return false;

      const fromDate = this.getDateFromString(date_from);
      const toDate = this.getDateFromString(date_to);
      const isExpired = checkIfDateExpired(date_to);

      if (!fromDate || !toDate) return false;

      const now = new Date();
      return fromDate < now && !isExpired;
    },
    getDiscountPrice (product = {}) {
      return Number(product.special_price_discount) || 0
    },
    getPromotionText (text) {
      return text || ''
    },
    getDiscountDay (product) {
      if (!isNil(product.special_price_to_date)) {
        if (typeof product.special_price_to_date === 'string') {
          return getDiscountDate(product).date.day
        } else {
          return product.special_price_to_date.date.day
        }
      }
    },
    getDiscountMonth (product) {
      if (!isNil(product.special_price_to_date)) {
        if (typeof product.special_price_to_date === 'string') {
          return getDiscountDate(product).date.month
        } else {
          return product.special_price_to_date.date.month
        }
      }
    },
    getIsShowPromotionDate (label) {
      return label.date_from && label.date_to && (label.add_date || label.add_countdown)
    },
    getPromotionDateText (label) {
      let dateText = ''

      if (label.add_date) {
        dateText = this.$t('to') + ' ' + this.getPromotionDay(label) + '.' + this.getPromotionMonth(label)
      } else if (label.add_countdown && label.date_to) {
        dateText = ' | ' + getFormatTimer(label.date_to).days + ' ' + this.getLabelDay(getFormatTimer(label.date_to).days)
      }

      return dateText
    },
    getLabelDay (day) {
      return declension([this.$t('day'), this.$t('days'), this.$t('days_')], day)
    },
    getPromotionDay (label) {
      if (typeof label.date_to !== 'string') return ''

      return this.getPromotionDate(label.date_to).date.day
    },
    getPromotionMonth (label) {
      if (typeof label.date_to !== 'string') return

      return this.getPromotionDate(label.date_to).date.month
    },
    getPromotionDate (date = '') {
      if (date === '') {
        return date
      } else {
        const parts = date.split(' ')
        const dateParts = parts[0].split('-')
        const formatDate = new Date(Number(dateParts[0]), Number(dateParts[1]) - 1, Number(dateParts[2]))

        return {
          date: {
            year: dateParts[0],
            month: dateParts[1],
            day: dateParts[2]
          },
          formatDate: formatDate
        }
      }
    },
    getLabelStyle (label) {
      if (!label || !label.color) return {}
      return {
        'color': 'var(--black);',
        'background-color': label.color
      }
    }
  }
}
