<template>
  <div
    class="a-bonus-badge"
    v-if="varusPerfect"
  >
    <span class="a-bonus-badge__logo">
      <svg xmlns="http://www.w3.org/2000/svg" width="12" height="14" fill="none">
        <path fill="#E5E5E5" d="M0 0h12v14H0z" />
        <path fill="#fff" d="M-360-3239h1366V351H-360z" />
        <rect width="188" height="119" x="-25.5" y="-81.5" fill="#F3F4F6" rx="4.5" />
        <rect width="115" height="24" x="-6" y="-6" fill="#FDF0ED" rx="12" />
        <g filter="url(#a)">
          <circle cx="6" cy="6" r="12" fill="#EB6747" />
          <circle cx="6" cy="6" r="11.25" stroke="#fff" stroke-width="1.5" />
        </g>
        <g fill-rule="evenodd" clip-rule="evenodd">
          <path fill="#fff" d="M5.839 0A9.115 9.115 0 0 0 4.5 1.799c-.342.607-.6 1.259-.768 1.938a7.24 7.24 0 0 0-1.543-.87 8.903 8.903 0 0 0-2.136-.573 9.309 9.309 0 0 0 .03 2.257c.072.514.197 1.02.373 1.507a6.25 6.25 0 0 1 5.382 1.486 6.25 6.25 0 0 1 5.397-1.483 7.49 7.49 0 0 0 .366-1.51c.1-.748.11-1.506.03-2.257-.732.1-1.45.293-2.136.573a7.227 7.227 0 0 0-1.544.87 7.52 7.52 0 0 0-.76-1.938A9.195 9.195 0 0 0 5.84 0Z" />
          <path fill="#AAC91D" d="M.569 6.8c.052.995.378 1.955.939 2.77a5.208 5.208 0 0 0 2.232 1.835 6.546 6.546 0 0 1 1.575-3.328 5.52 5.52 0 0 0-3.652-1.38A5.383 5.383 0 0 0 .57 6.8ZM4.305 13.145l.345.168c.205-.51.436-1.01.694-1.495.164.014.328.024.495.024 2.825 0 5.133-2.235 5.27-5.042a5.544 5.544 0 0 0-4.786 1.302 5.786 5.786 0 0 0-1.878 3.547h.023a9.077 9.077 0 0 1 4.339-3.518l.1-.036a11.461 11.461 0 0 0-3.834 3.69c-.282.438-.538.892-.768 1.36Z" />
        </g>
        <rect width="188" height="119" x="-25.5" y="-81.5" stroke="#7B61FF" stroke-dasharray="10 5" rx="4.5" />
        <defs>
          <filter id="a" width="33.6" height="33.6" x="-10.8" y="-9" color-interpolation-filters="sRGB" filterUnits="userSpaceOnUse">
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
            <feOffset dy="1.8" />
            <feGaussianBlur stdDeviation="2.4" />
            <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0" />
            <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_5460_233703" />
            <feBlend in="SourceGraphic" in2="effect1_dropShadow_5460_233703" result="shape" />
          </filter>
        </defs>
      </svg>
    </span>
    <div class="a-bonus-badge__text">
      <span>+{{ varusPerfect.toFixed(0) }}₴</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ABonusBadge',
  props: {
    varusPerfect: {
      type: Number,
      required: true,
      default: 0
    }
  }
};
</script>

<style lang="scss">
@import '~theme/css/fonts';

.a-bonus-badge {
  display: flex;
  align-items: center;
  background: var(--orange-light-background);
  border-radius: var(--spacer-14);
  margin: 0 auto var(--spacer-10) 0;
  height: var(--spacer-24);
  padding: 0 var(--spacer-5) 0 0;
  max-width: 165px;
  width: fit-content;

  &__logo {
    width: var(--spacer-24);
    height: var(--spacer-24);
    left: 0;
    border-radius: 50%;
    position: relative;
    background: var(--orange);
    border: 1.8px solid var(--white);
    box-sizing: border-box;
    box-shadow: 0 1.8px 4.8px rgba(0, 0, 0, 0.08);
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 1px;

    svg {
      width: var(--spacer-12);
      height: auto;
    }

  }

  &__text {
    font-weight: 400;
    font-size: var(--font-size-12);
    line-height: var(--spacer-15);
    color: var(--orange);
    display: flex;
    align-items: center;
    box-sizing: border-box;
    white-space: nowrap;
    overflow: hidden;
    &::-webkit-scrollbar {
      display: none;
    }

    span {
      font-weight: 500;
      font-size: var(--font-size-16);
      line-height: var(--spacer-19);
      color: var(--orange);
      margin-right: var(--spacer-7);
    }
  }
}
</style>
