<template>
  <SfButton
    class="sf-button--pure a-add-to-wishlist"
    data-testid="addToWishlist"
    :data-transaction-name="isOnWishlist ? 'Remove from Wishlist' : 'Add to Wishlist'"
    @click="onClickHandler"
  >
    <span class="a-add-to-wishlist__heart" :class="favoriteIcon" />
  </SfButton>
</template>

<script>
import { SfButton } from '@storefront-ui/vue';
import { mapGetters, mapActions } from 'vuex';
import { ModalList } from 'theme/store/ui/modals'

export default {
  name: 'AAddToWishlist',
  components: {
    SfButton
  },
  props: {
    product: {
      required: true,
      type: Object
    },
    wishlistId: {
      type: Number,
      default: 0
    },
    title: {
      type: String,
      default: 'Add to favorite'
    },
    isCart: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters({
      isLoggedIn: 'user/isLoggedIn',
      getWishlists: 'wishlist/getWishlists'
    }),
    favoriteIcon () {
      return this.isOnWishlist ? 'favorite' : 'favorite_border';
    },
    isOnWishlist () {
      return this.$store.getters['wishlist/isOnWishlist'](this.product)
    }
  },
  methods: {
    ...mapActions({
      openModal: 'ui/openModal'
    }),
    onClickHandler () {
      if (!this.isLoggedIn) {
        this.openModal({ name: ModalList.Auth, payload: 'auth' })
        return
      }

      this.openWishlistManagerModal(this.product)
    },
    openWishlistManagerModal () {
      this.openModal({
        name: ModalList.WishlistManager,
        payload: {
          products: [this.product],
          title: this.$t(this.title),
          isAddFromCart: this.isCart
        }
      })
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~theme/css/fonts";

.a-add-to-wishlist {
  height: 17px;
  width: 20px;

  &__heart {
    color: var(--gray3);
    font-size: var(--font-size-17);
    @include font-icon(var(--icon-heart));
    display: flex;
    justify-content: center;
    align-items: center;
    transition: color 200ms;

    &:hover {
      color: var(--orange-hover);
    }

    &.favorite {
      color: var(--orange);
    }
  }
}

</style>
