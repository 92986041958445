export function getFormatTimer (date: string) {
  const dayMilliseconds = 24 * 60 * 59 * 1000;
  const countDownDate = new Date(date).getTime() + dayMilliseconds;
  const nowDate = new Date().getTime();
  const distance = countDownDate - nowDate;
  let timer = {}
  if (distance > 0) {
    const days = Math.floor(distance / (1000 * 60 * 60 * 24));
    const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((distance % (1000 * 60)) / 1000);
    timer = {
      days,
      hours,
      minutes,
      seconds,
      distance
    }
  } else {
    timer = {
      days: 0,
      distance: 0
    }
  }
  return timer
}
